import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout/Layout';
import Gallery from '../components/Gallery/Gallery';
import Hero from '../components/Hero/Hero';

const galerija = ({ data }) => {
  const heroImage = data.heroImage.edges[0].node.childImageSharp.fluid;

  return (
    <Layout>
      <SEO title="Galerija - Monolit" />

      <Hero image={heroImage} text="Galerija" breadcrumbs={true} />

      <div className="container padding-5-1-1">
        <Gallery columns={4} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    heroImage: allFile(filter: { relativeDirectory: { eq: "hero/gallery" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default galerija;
