import React, { useState, useEffect } from 'react';
import Backdrop from '../Backdrop/Backdrop';
import NavigationButtons from '../../UI/Buttons/NavigationButtons/NavigationButtons';

import styles from './Modal.module.scss';

const Modal = ({
  show,
  children,
  close,
  keyPress,
  pagination,
  navigation,
  next,
  prev,
  role,
  width,
}) => {
  let classes = styles.Modal;

  const [htmlSelector, setHtmlSelector] = useState(null);

  useEffect(() => {
    setHtmlSelector(document.querySelector('html'));
  }, []);

  const lockClass = 'lock-scroll';

  if (htmlSelector) {
    if (show) {
      classes = [styles.Modal, styles.Show].join(' ');
      htmlSelector.classList.add(lockClass);
    } else {
      htmlSelector.classList.remove(lockClass);
    }
  }

  let navBtns = navigation ? (
    <NavigationButtons modal={true} next={next} prev={prev} />
  ) : null;

  return (
    <>
      <Backdrop show={show} clicked={close} />
      <aside
        className={classes}
        aria-modal="true"
        role={role}
        tabIndex="-1"
        onKeyDown={keyPress}
        ref={modal => modal && modal.focus()}
        style={{ width: width ? width : '80%' }}
      >
        <div className={styles.TopBar}>
          <span className={styles.Pagination}>{pagination}</span>
          <span
            className={styles.Close}
            aria-label="Close Modal Box"
            role="button"
            onClick={close}
          >
            ×
          </span>
        </div>

        <div className={styles.Body}>{children}</div>
        {navBtns}
      </aside>
    </>
  );
};

export default Modal;
