import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Modal from '../UI/Modal/Modal';
import styles from './Gallery.module.scss';

const Gallery = props => {
  const [index, setIndex] = useState(0);
  const [modal, setModal] = useState(false);
  const [largeImage, setLargeImage] = useState(null);
  const [modalWidth, setModalWidth] = useState(0);

  const { thumbImages, largeImages } = useStaticQuery(
    graphql`
      query {
        thumbImages: allFile(
          sort: { fields: name, order: DESC }
          filter: { relativeDirectory: { eq: "gallery" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(quality: 70, maxWidth: 300) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }

        largeImages: allFile(
          sort: { fields: name, order: DESC }
          filter: { relativeDirectory: { eq: "gallery" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(quality: 100, maxWidth: 2048) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  );

  //Minus 1 for array offset from 0
  const length = thumbImages.edges.length - 1;

  const pagination = `${index + 1} / ${length + 1}`;

  let images = null;

  images = thumbImages.edges.map((item, i) => {
    const imageData = item.node.childImageSharp.fluid;
    const largeImgData = largeImages.edges[i].node.childImageSharp.fluid;
    return (
      <div
        key={item.node.id}
        className={styles.ImageWrapper}
        onClick={() => imageOpenHandler(largeImgData, i)}
      >
        <Img
          fluid={imageData}
          alt={item.node.name.replace(/-/g, ' ').substring(2)}
          style={{ width: '100%', height: '250px' }}
          className={styles.Thumbnail}
        />
      </div>
    );
  });

  const handleNext = () => {
    let updatedIndex = index + 1;
    index === length ? setIndex(0) : setIndex(updatedIndex);

    updatedIndex = updatedIndex === length + 1 ? 0 : updatedIndex;

    const largeImageData = (
      <Img
        fluid={largeImages.edges[updatedIndex].node.childImageSharp.fluid}
        imgStyle={{ objectFit: 'contain' }}
        className={styles.Large}
      />
    );

    setLargeImage(largeImageData);
  };

  const handlePrevious = () => {
    let updatedIndex = index - 1;
    index === 0 ? setIndex(length) : setIndex(updatedIndex);

    updatedIndex = updatedIndex === -1 ? length : updatedIndex;

    const largeImageData = (
      <Img
        fluid={largeImages.edges[updatedIndex].node.childImageSharp.fluid}
        imgStyle={{ objectFit: 'contain' }}
        className={styles.Large}
      />
    );

    setLargeImage(largeImageData);
  };

  const imageOpenHandler = (imageData, i) => {
    const largeImageData = (
      <Img
        fluid={imageData}
        imgStyle={{ objectFit: 'contain' }}
        className={styles.Large}
      />
    );

    setIndex(i);
    setLargeImage(largeImageData);
    setModal(true);
  };

  const modalKeyHandler = ({ keyCode }) => {
    // keyCode = event.keyCode

    switch (keyCode) {
      case 27:
        setModal(false);
        break;
      case 37:
        handlePrevious();
        break;
      case 39:
        handleNext();
        break;
      default:
        return false;
    }
  };

  const modalCloseHandler = e => {
    setModal(false);
  };

  useEffect(() => {
    if (largeImage) {
      const aspectRatio = largeImage.props.fluid.aspectRatio;
      const innerHeight = window.innerHeight;
      const heightPercentage = innerHeight * 0.1;
      const height = innerHeight - heightPercentage;
      const imageWidth = height * aspectRatio;

      setModalWidth(imageWidth);
    }
  }, [largeImage]);

  return (
    <>
      <Modal
        show={modal}
        close={modalCloseHandler}
        keyPress={modalKeyHandler}
        pagination={pagination}
        navigation={true}
        next={() => handleNext()}
        prev={() => handlePrevious()}
        role="dialog"
        width={modalWidth}
      >
        {largeImage}
      </Modal>
      <section className={styles.Gallery}>{images}</section>
    </>
  );
};

export default Gallery;
